import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { PrivacyData } from '../data/privacy-db';
import Layout from '../components/layout';

export default function PrivacyPolicy() {
  return (
    <Layout>
      <section className="privacy_custom" style={{ marginTop: '100px' }}>
        <Container>
          <h2>{PrivacyData[0].heading}</h2>
          <p>{PrivacyData[0].text}</p>
          {PrivacyData[0].data.map(({ id, heading, text }) => (
            <Row key={id}>
              <Col>
                <h4>
                  {heading}
                </h4>
                <p>{text}</p>
              </Col>
            </Row>
          ))}
        </Container>
      </section>
    </Layout>
  );
}
