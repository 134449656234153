/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
import { Link } from 'gatsby';
import React from 'react';

export const PrivacyData = [{
  id: 1,
  heading: 'Privacy Policy',
  text:
  <span>
    <p>Infenox (“Infenox”, “we”, “us” or “our”) respects the privacy of our users (“user” or “you”). This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website infenox.com or access any other media form, media channel, mobile website, or mobile application related or connected thereto (collectively, the “Site”). Please read this Privacy Policy carefully. If you do not agree with the terms of this privacy policy, please do not access the site. Your personal information may be processed in the country where it was collected as well as other countries (including the United States) where laws regarding processing of personal information may be less stringent than the laws in your country. By uploading personal information to the services, you warrant that you have the right to transfer such information outside your country and into the United States.</p>
    <p>We reserve the right to make changes to this Privacy Policy at any time and for any reason. We will alert you about any changes by updating the “Effective” date of this Privacy Policy. Any changes or modifications will be effective immediately upon posting the updated Privacy Policy on the Site, and you waive the right to receive specific notice of each such change or modification. You are encouraged to periodically review this Privacy Policy to stay informed of updates. You will be deemed to have been made aware of, will be subject to, and will be deemed to have accepted the changes in any revised Privacy Policy by your continued use of the Site after the date such revised Privacy Policy is posted.</p>
  </span>,
  data: [
    {
      id: 1,
      heading: 'Collection of Your Information',
      text:
  <span>
    <p>We may collect information about you in a variety of ways. The information we may collect on the Site includes:</p>
    <p>
      <i>Contact Information. </i>
      If you use the Contact Us feature of the Site or contact us in another way, you may provide us with your full name, phone number, email address, city, state, zip code and information about the nature of your request, question or comment.
    </p>
    <p>
      <i>Account Information.</i>
      {' '}
      The Site allows some users register to create an account with us. To create an account, you will provide us with your name, email, phone number and account password.
    </p>
    <p><i>Business Data. We collect information related to other employees, owners, directors, officers, or contractors of a third-party organization (e.g., business, company, partnership, sole proprietorship, nonprofit, or government agency) with whom we may conduct, or possibly conduct, business activities.</i></p>
    <p>
      <i>Job Applicant Information.</i>
      {' '}
      If you fill out an application for a job available on the Site, you will be asked to provide us with personal information about yourself so we can evaluate your application (“Job Applicant Information”),such as Contact Information; information in your curriculum vitae (“CV”), resume, cover letter, or similar documentation; educational background including degrees, certificates, transcripts; credit history and/or criminal background screenings (where relevant to your application and permitted under applicable law); and, other unique information in your application that you choose to share with us, such as personal preferences, hobbies, social preferences, etc. If you have a disability and would like for us to consider an accommodation, you may provide that information during the recruitment process. Infenox may also ask for information such as race, ethnicity, or gender for the purposes of monitoring our equal opportunity program and compliance. However, for the purposes of your application this information is voluntary. If you provide us with this information, it will not be considered in the hiring or selection process.
    </p>
    <p>
      <i>Marketing Data.</i>
      {' '}
      We collect and retain data on your marketing preferences and your subscriptions to our publications.
    </p>
    <p>
      <i>Your Feedback.</i>
      {' '}
      You may provide us, and we retain, feedback from you about our Site as well as our products and services generally, which may include data gathered from any surveys in which you participate.
    </p>
    <p>
      <i>Usage and Technical Data.</i>
      {' '}
      Like most website operators, Infenox collects certain technical data when you use our Site, such as a user’s Internet Protocol (IP) address, browser type, language preference, referring site, and the date and time when users visit the Site. We may also collect information about the device you’re using to access the Site, including the type of device, the operating system, device settings, and device identifiers.
    </p>
    <p>When you do not provide personal information that we request, we may not be able to provide you with the requested service or complete a transaction, and you agree that Infenox will not be liable or otherwise responsible for any actions resulting therefrom.</p>
  </span>,
    },
    {
      id: 2,
      heading: 'Information Sources',
      text: <span><p>Most often, we collect personal information directly from you, such as when you directly engage with us. We may also collect personal information about, or related to you, from third parties, such as our recruitment vendors, background and credit check providers, public agencies responsible for assisting in the employment eligibility and verification process, publicly available sources, and your identified references. We may also obtain information about you from public sources or third parties. For example, you may also choose to provide us with access to certain personal information stored by third parties such as job-related social media sites (e.g., LinkedIn). We do not request, and please do not provide us with, any details that can be used to access or control any of your social media accounts.</p></span>,
    },
    {
      id: 3,
      heading: 'Use of Your Information',
      text:
  <span>
    <p>Infenox may use personal information for several business and administrative purposes, or to further our legal or other business interests. Generally, we use personal information for the following reasons:</p>
    <p>
      <i>Services</i>
      {' '}
      We may use your personal information to deliver services to you.
    </p>
    <p>
      <i>Improving our Business.</i>
      {' '}
      We may use your personal information to perform business analyses or for other purposes that help us to develop and improve the quality of our business, products and services. We may use your personal information to conduct research, analyze your purchasing preferences, identify the products and services that best meet your requirements, and measure the effectiveness of the advertising we serve you. Where permitted by law, we may combine the information that we collect via our Site with other information we hold about you (such as information about your use of our products and services) in order to offer you an improved and consistent customer experience when interacting with us or for other purposes set forth in this Privacy Policy.
    </p>
    <p>
      <i>Improving our Site.</i>
      {' '}
      We use the information we collect to provide full access and functionality of the Site, such as to enable users to enjoy and easily navigate the Site; to protect against users seeking to hack into the Site; and to assess the level of general interest in the Site.
    </p>
    <p>
      <i>Marketing.</i>
      {' '}
      In accordance with applicable laws and regulations, we may use your personal information to inform you of our products or services which may be of interest to you, and to otherwise communicate with you about offerings, events, news, surveys, special offers, and related topics. You are able to opt-out from marketing communications sent via e-mail at any time, free of charge by using the “unsubscribe” link in any e-mail marketing materials you receive from us.
    </p>
    <p>
      <i>Enforcement and Business Interests.</i>
      {' '}
      We may use the personal information we collect in order to detect, prevent and respond to fraud, intellectual property infringement, violations of our terms and conditions, violations of law or other misuse of our Site, products, or facilities. We may use personal information to administer and protect our business and our Site, including system maintenance, support, reporting and hosting of data. We may, in our sole discretion, use personal information to promote, defend or protect our legal, regulatory, or business interests.
    </p>
    <p>
      <i>Job Applicant Information.</i>
      {' '}
      We use Job Applicant Information to identify and evaluate job applicants, including assessing skills, qualifications, and interests; communicate with applicants about the recruitment process and your application; comply with our legal, judicial, regulatory, administrative, or other corporate requirements; analyze and improve our application and recruitment process, including by executing satisfaction surveys; accommodate individuals who may have specialized needs during the employment process.
    </p>
  </span>,
    },
    {
      id: 4,
      heading: 'Disclosure of Your Information',
      text:
  <span>
    <p>We may disclose non-personally identifiable aggregated information about our users without restriction. We may also share personally identifiable information we have collected about you in certain situations. Your information may be disclosed as follows:</p>
    <p>
      <i>Corporate Group Sharing.</i>
      {' '}
      We share personal information among our affiliates and subsidiaries and these recipients may use your personal information in accordance with the terms and conditions of this privacy policy (e.g., marketing, surveys) or their own data privacy representations. In addition to the foregoing, Infenox may provide, or leverage for our own use, computer networks and systems of affiliates and subsidiaries and the personal information may be transmitted or retained thereon.
    </p>
    <p>
      <i>By Law or to Protect Rights.</i>
      {' '}
      If we believe the release of information about you is necessary to respond to legal process, to investigate or remedy potential violations of our policies, or to protect the rights, property, and safety of others, we may share your information as permitted or required by any applicable law, rule, or regulation. This includes exchanging information with other entities for fraud protection and credit risk reduction.
    </p>
    <p>
      <i>Third-Party Service Providers.</i>
      {' '}
      We may share your information with third parties that perform services for us or on our behalf, including data analysis, email delivery, hosting services, customer service, and marketing assistance.
    </p>
    <p>
      <i>Other Third Parties.</i>
      {' '}
      We may share your information with advertisers and investors for the purpose of conducting general business analysis. We may also share your information with such third parties for marketing purposes, as permitted by law.
    </p>
    <p>
      <i>Sale or Bankruptcy.</i>
      {' '}
      If we reorganize or sell all or a portion of our assets, undergo a merger, or are acquired by another entity, we may transfer your information to the successor entity. If we go out of business or enter bankruptcy, your information would be an asset transferred or acquired by a third party.
    </p>
  </span>,
    },
    {
      id: 5,
      heading: 'Tracking Technologies',
      text:
  <span>
    <p><i>Cookies and Web Beacons</i></p>
    <p>We may use cookies, web beacons, tracking pixels, and other tracking technologies on the Site to help customize the Site and improve your experience. For more information on how we use cookies, please refer to our Cookie Policy posted on the Site, which is incorporated into this Privacy Policy. By using the Site, you agree to be bound by our Cookie Policy.</p>
    <p><i>Website Analytics</i></p>
    <p>
      We may also partner with selected third-party vendors to allow tracking technologies and remarketing services on the Site through the use of first party cookies and third-party cookies, to, among other things, analyze and track users’ use of the Site, determine the popularity of certain content and better understand online activity. By accessing the Site, you consent to the collection and use of your information by these third-party vendors. You are encouraged to review their privacy policy and contact them directly for responses to your questions. We do not transfer personal information to these third-party vendors. However, If you do not want any information to be collected and used by tracking technologies, you can visit the third-party vendor or
      {' '}
      <a href="#">Network Advertising Initiative Opt-Out Tool</a>
      {' '}
      or
      {' '}
      <a href="#">Digital Advertising Alliance Opt-Out Tool</a>
      .  You should be aware that getting a new computer, installing a new browser, upgrading an existing browser, or erasing or otherwise altering your browser’s cookies files may also clear certain opt-out cookies, plug-ins, or settings.
    </p>
    <p>In particular, we use the following tools:</p>
    <ol>
      <li><i><b>Google Analytics</b></i></li>
      <li>
        <i><b>Google Maps API</b></i>
        {' '}
        This website uses Google Maps APIs. You may find the Google Maps APIs Terms of Service
        {' '}
        <a href="https://cloud.google.com/maps-platform/terms">here</a>
        . To better understand Google’s Privacy Policy, please refer to this
        {' '}
        <a href="https://developers.google.com/terms/api-services-user-data-policy">link</a>
        .
      </li>
    </ol>
  </span>,
    },
    {
      id: 6,
      heading: 'Third-party Websites',
      text: <span><p>The Site may contain links to third-party websites and applications of interest, including advertisements and external services, that are not affiliated with us. Once you have used these links to leave the Site, any information you provide to these third parties is not covered by this Privacy Policy and will be subject to the privacy policy and terms of service of the third-party providing the site or service. Before visiting and providing any information to any third-party websites, you should inform yourself of the privacy policies and practices (if any) of the third party responsible for that website, and should take those steps necessary to, in your discretion, protect the privacy of your information. We are not responsible for the content or privacy and security practices and policies of any third parties, including other sites, services or applications that may be linked to or from the Site. If you no longer wish to receive correspondence, emails or other communications from third parties, you are responsible for contacting the third party directly.</p></span>,
    },
    {
      id: 7,
      heading: 'Biometric Data and Internal Operations',
      text: <span><p>The Company may collect, retain, and use biometric data (e.g., fingerprints) to assist with our internal business operations, such as identification and authentication, timekeeping and attendance, and safety and security. In such circumstances, we will furnish to individuals who provide us with biometric data other, formal notice of this activity and, to the extent required by law, obtain their consent for the same. We will retain this biometric data for no more than one (1) year from the individual’s last interaction with us, unless we are required to retain it longer for legal or regulatory compliance purposes, or to exercise or defend our legal interests. We have implemented commercially reasonable protocols to safeguard and, when appropriate, to permanently delete or dispose of such biometric data.</p></span>,
    },
    {
      id: 8,
      heading: 'Security of Your Information',
      text: <span><p>We use administrative, technical, and physical security measures to help protect your personal information. While we have taken reasonable steps to secure the personal information you provide to us, please be aware that despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed against any interception or other type of misuse. Any information disclosed online is vulnerable to interception and misuse by unauthorized parties. Therefore, we cannot guarantee complete security if you provide personal information.</p></span>,
    },
    {
      id: 9,
      heading: 'Our Data Retention Criteria',
      text: <span><p>The period during which we store your personal information varies depending on the purpose for the processing. For example, we store personal information needed to provide you with products and services, or to facilitate transactions you have requested, for so long as you are a customer of Infenox. We store your personal information for marketing purposes until you have opted-out of receiving further direct marketing communications in accordance with applicable law. In all other cases, we store your personal information for as long as is needed to fulfill the purposes outlined in this Privacy Policy, following which time it is either anonymized (where permitted by applicable law), deleted or destroyed.</p></span>,
    },
    {
      id: 10,
      heading: 'Data Location',
      text:
  <span>
    <p>Infenox is based in the Canada and the personal information that we collect and process is retained and stored in Canada. Please be aware that </p>
    <ol type="i">
      <li>Canada may not provide the same level of protection of personal information as in your country, state, or jurisdiction of residence or nationality,</li>
      <li>the European Union and other foreign authorities have determined that, in some circumstances, Canada does not provide an adequate level of protection for personal information, and</li>
      <li>when transferred to Canada, your personal information may be accessible by, or otherwise made available to, Canada authorities and officials pursuant to judicial and/or administrative orders, decrees, and demands, and/or other domestic laws, statutes, and regulations, applicable in Canada. By continuing to provide us such information you hereby consent to your personal information being transferred to, and stored in, Canada.</li>
    </ol>
  </span>,
    },
    {
      id: 11,
      heading: 'We Do Not Sell Your Personal Information',
      text: <span><p>Infenox does not, to the best of our knowledge, sell or rent, personal information that we have collected or retain about you to any other third-party for any purpose. Accordingly, we do not offer individuals the ability to “opt-out” of the selling or renting of personal information because we do not engage in those practices.</p></span>,
    },
    {
      id: 12,
      heading: 'Policy for Children',
      text: <span><p>We do not knowingly solicit information from or market to children under the age of 13 and children under 13 years of age are not permitted to visit the Site for any reason. If you become aware of any data we have collected from children under age 13, please contact us using the contact information provided below and we will delete the information.</p></span>,
    },
    {
      id: 13,
      heading: 'Your Rights and Responsibilities',
      text:
  <span>
    <p>You are permitted, and hereby agree, to only provide personal information to Infenox if such personal information is accurate, reliable, and relevant to our relationship and only to the extent such disclosure will not violate any applicable data protection law, statute, or regulation.</p>
    <p>You may have certain rights under applicable data protection law with respect to personal information about you that is collected through the Sites or when you contact or otherwise engage with us. To exercise any of these data privacy rights, please contact us, or have your authorized agent contact us, in accordance with the “Contact Us” section listed below. In the event you submit, or your authorized agent submits on your behalf, a data request, you (and your authorized agent) hereby acknowledge and agree, under penalty of perjury, that you are (or the authorized agent of) the consumer whose personal information is the subject of the request. We will respond to any data requests within the timeframes required by law, and we may charge a fee to facilitate your request where permitted by law.</p>
    <p>
      <i><b>Marketing</b></i>
      . You have the right to opt-out of receiving electronic direct marketing communications from us. All electronic direct marketing communications that you may receive from us, such as e-mail messages,
    </p>
    <p>
      <i><b>California Privacy Rights</b></i>
      .  California Civil Code Section § 1798.83 permits users of the Sites that are California residents to request certain information regarding our disclosure of personal information to third parties for their direct marketing purposes. Pursuant to the California Consumer Privacy Act of 2018, as amended (“CCPA”), California residents may have certain data privacy rights, such as the right to be notified about what personal information categories are collected about you, and our intended use and purpose for collecting your personal information. You have the right to request access to your personal information and, to the extent feasible, request that it be transmitted in certain forms and formats. You may have the right to request that we (and any applicable service provider) delete your personal information. You have the right not to be subject to discrimination for asserting your rights under the CCPA. If you make, or an authorized agent on your behalf makes, any request related to your personal information under the CCPA, Infenox will ascertain your identity (and the identity of the authorized agent, to the extent applicable) to the degree of certainty required under the law before addressing your request. Unifi may require you to match at least two or three pieces of personal information we have previously collected from you before granting you access to, or erasing, specific pieces, or categories of, personal information, or otherwise responding to your request. We may require written documentation that demonstrates a third party is authorized to serve as your agent for the purposes of submitting the requests set forth herein.
    </p>
    <p>
      <i><b>Persons with Disabilities</b></i>
      . Infenox strives to ensure that every person has access to information related to our products and services, including this Policy. Please contact us if you would like this Policy provided in an alternative format and we will seek to meet your needs.
    </p>
    <p>
      <i><b>Do-Not-Track Features</b></i>
      .  Most web browsers and some mobile operating systems include a Do-Not-Track (“DNT”) feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. No uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this Privacy Policy.
    </p>
    <p>
      <i><b>Nevada Privacy Disclaimer</b></i>
      .  Pursuant to Nevada law, a Nevada “consumer” (as the term is defined therein), may, at any time, submit a verified request through a designated request address to an “operator” directing the operator not to make any sale of his or her personal information that the operator has collected or will collect about the consumer. For clarity purposes, Infenox does not sell or exchange your personal information for monetary consideration to a third party for the third party to license or sell the information to additional persons or parties.
    </p>
  </span>,
    },
    {
      id: 14,
      heading: 'Contact Us',
      text:
  <span>
    <p>
      If you have questions or comments about this Privacy Policy, please email us at [
      {' '}
      <a href="mailto: media@infenox.com">media@infenox.com</a>
      {' '}
      ] or contact us
      {' '}
      <Link to="/contact">here</Link>
      .
    </p>
  </span>,
    },
  ],
}];
